import React from "react"
import Layout from "../components/Layout"
import {graphql, Link} from "gatsby"
import SEO from "../components/SEO"
import ReactMarkdown from "react-markdown";

const About = ({
  data: {
    about: { nodes },
  },
}) => {
  const { content } = nodes[0]

  return (
    <Layout>
      <SEO title="Privacy Policy" description="about webdev" />
      <section className="blog-template">
        <div className="section-center">
          <article>
            <ReactMarkdown source={content} />
          </article>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    about: allStrapiPrivacy {
      nodes {
        content
      }
    }
  }
`

export default About
